<template>
  <div>
    <TosListVuetify entityName="convogli" :headers="headers" :initFiltersFunction="initFilters" :excludeButtons="excludeButtons">
      <template #customFilters="slotProps">
        <v-row>
          <v-col cols="2">
            <v-text-field :label="tLabel('Codice/Codice esterno/Nome')" v-model="slotProps.data.codiceCodiceEsternoNomeViaggioInOrOut" @keyup.enter="$emit('refreshTable')" clearable/>
          </v-col>

          <v-col cols="1">
            <v-select
              ref="statoSelect"
              :label="tLabel('Stato')"
              v-model="slotProps.data.stato___EQ"
              :items="listStatiConvoglio"
              @keyup.enter="$emit('enterOnSelect', 'statoSelect')"
              clearable
            />
          </v-col>

          <v-col cols="1">
            <v-select
              ref="posizioneSelect"
              :label="tLabel('posizione')"
              v-model="slotProps.data.posizione"
              :items="listPosizioniConvoglio"
              @keyup.enter="$emit('enterOnSelect', 'posizioneSelect')"
              clearable
            />
          </v-col>

          <v-col cols="1">
            <v-select
              ref="provenienzaSelect"
              :label="tLabel('Provenienza')"
              v-model="slotProps.data.viaggioIn_nodoPartenzaId"
              :items="listNodi"
              v-if="slotProps.data.tracciaId == null"
              item-value="id"
              item-text="descrizione"
              @keyup.enter="$emit('enterOnSelect', 'provenienzaSelect')"
              clearable
            />
          </v-col>

          <v-col cols="2">
            <CalendarFilter
              :label="tLabel('In arrivo dal').toUpperCase()"
              v-model="slotProps.data.eta___GT"
              @input="
                (a) => {
                  slotProps.data.eta___GT = a;
                }
              "
            />
          </v-col>
          <v-col cols="1">
            <CalendarFilter
              :label="tLabel('al').toUpperCase()"
              v-model="slotProps.data.eta___LT"
              @input="
                (a) => {
                  slotProps.data.eta___LT = a;
                }
              "
            />
          </v-col>
          <v-col cols="2">
            <CalendarFilter
              :label="tLabel('In partenza dal').toUpperCase()"
              v-model="slotProps.data.etd___GT"
              @input="
                (a) => {
                  slotProps.data.etd___GT = a;
                }
              "
            />
          </v-col>
          <v-col cols="1">
            <CalendarFilter
              :label="tLabel('al').toUpperCase()"
              v-model="slotProps.data.etd___LT"
              @input="
                (a) => {
                  slotProps.data.etd___LT = a;
                }
              "
            />
          </v-col>
          <v-col cols="1">
            <v-checkbox :label="tLabel('Mostra vuoti')" v-model="mostraVuoti" @click="modificaMostraVuoti(slotProps.data)" />
          </v-col>
        </v-row>
        <v-row v-if="faseOperativa == 'debug'">
          <v-col>
            <v-text-field :label="tLabel('targa carro')" v-model="slotProps.data.targaCarro" @keyup.enter="$emit('refreshTable')" />
          </v-col>
        </v-row>
      </template>

      <template #item.eta="slotProps">{{ slotProps.data.eta | formatDateTimeFromDate }}</template>
      <template #item.etd="slotProps">{{ slotProps.data.etd | formatDateTimeFromDate }}</template>
      <template #item.ata="slotProps">{{ slotProps.data.ata | formatDateTimeFromDate }}</template>
      <template #item.atd="slotProps">{{ slotProps.data.atd | formatDateTimeFromDate }}</template>
      <template #item.listViaggiDestOut="slotProps">
        <div class="listViaggiDestOut">
          <div v-for="item in slotProps.value" v-bind:key="item.id">{{ viewNomeViaggio() ? item.nome : item.codice }} --> {{ item.nodoArrivo }}</div>
        </div>
      </template>

      <template #item.actions="slotProps">
        <div class="css-group-and-align-element css-centered">
          <v-btn
          v-if="!['carico', 'scarico'].includes(faseOperativa)" 
            icon
            :title="tLabel('Apri Dashboard convoglio')" 
            @click="
              $router.push({
                path: `/rails/ConvoyVisualization/${slotProps.data.id}`,
              })
            "
          >
            <v-icon color="primary" medium>mdi-toolbox</v-icon>
          </v-btn>

          <v-menu offset-y v-if="!['carico', 'scarico'].includes(faseOperativa)" >
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" :title="tLabel('Reports')" icon v-bind="attrs" v-on="on">
                <v-icon color="primary" medium>mdi-file-document-multiple</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in getListaReportItems()" :key="index">
                <v-list-item-title @click="generaReport(item.tipo, slotProps.data)">{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn
            v-if="faseOperativa != 'carico'" 
            icon
            :title="tLabel('Lavora scarichi convoglio')"
            :disabled="disabledLavorazione(slotProps.data)"
            @click="
              $router.push({
                path: `/callTreno/LavorazioneConvoglioArrivo/${slotProps.data.id}`,
              })
            "
          >
            <v-icon color="primary" medium>mdi-arrow-up-bold-box-outline</v-icon>
          </v-btn>

          <v-btn
          v-if="faseOperativa != 'scarico'" 
            icon
            :title="tLabel('Lavora carichi convoglio')"
            :disabled="disabledLavorazione(slotProps.data)"
            @click="
              $router.push({
                path: `/callTreno/LavorazioneConvoglioPartenza/${slotProps.data.id}`,
              })
            "
          >
            <v-icon color="primary" medium>mdi-arrow-down-bold-box-outline</v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:editF="slotProp">
        <EditConvoglio :item="slotProp.data" />
      </template>
    </TosListVuetify>
  </div>
</template>

<script>
import EditConvoglio from "./EditConvoglio";
import TosListVuetify from "../../components/anagrafiche/TosListVuetify";
import CalendarFilter from "../../components/anagrafiche/CalendarFilter";
import FormatsMixins from "../../mixins/FormatsMixins";
import railsMixins from "../../components/rails/mixins/railsMixins";

export default {
  name: "convogli",
  props: {
    faseOperativa: {
      type: String,
      default: null,
    },
  },
  components: {
    EditConvoglio,
    TosListVuetify,
    CalendarFilter,
  },
  mixins: [FormatsMixins, railsMixins],
  data() {
    return {
      entityName: "convogli",
      listStatiConvoglio: [{ value: null, text: "" }],
      listPosizioniConvoglio: [
        { value: 0, text: this.tLabel("CREATO") },
        { value: 1, text: this.tLabel("INTERMINAL") },
        { value: 2, text: this.tLabel("USCITO") },
      ],
      listNodi: [],
      headers: [],
      mostraVuoti: false,
      excludeButtons: {
        add: true,
      },
    };
  },
  async mounted() {
    console.log("----------->", this.faseOperativa);
    const statiConvoglio = await this.$api.get(this.$apiConfiguration.BASE_PATH + "convogli/listStatiConvoglio");
    statiConvoglio.forEach((sc) => {
      this.listStatiConvoglio.push({ value: sc, text: this.tLabel(sc) });
    });

    const listNodiResp = await this.$api.get(this.$apiConfiguration.BASE_PATH + "nodi/list");
    this.listNodi = listNodiResp.data;
    this.listNodi.sort((a, b) => (a.descrizione > b.descrizione ? 1 : -1));

    this.headers.push(
      { text: `${this.tLabel("Nome")}`, value: "nome", width: "250px", fixed: true },
      { text: "ETA", value: "eta", width: "150px" },
      { text: "ATA", value: "ata", width: "150px" },
      { text: "ETD", value: "etd", width: "150px" },
      { text: "ATD", value: "atd", width: "150px" },
      { text: `${this.tLabel("Viaggio IN")}`, value: this.viewNomeViaggio() ? "viaggioInNome" : "viaggioIn", width: "100px" },
      { text: `${this.tLabel("Provenienza")}`, value: "viaggioIn_nodoPartenza", width: "150px" },
      { text: `${this.tLabel("Viaggi uscita")}`, value: "listViaggiDestOut", sortable: false, width: "100px" },
      { text: `${this.tLabel("Parco binari")}`, value: "binario_parco", width: "100px" },
      { text: `${this.tLabel("binario")}`, value: "binario_numero", width: "50px" }
    );

    if (!['carico', 'scarico'].includes(this.faseOperativa)) {
      this.headers.push(
        { text: `${this.tLabel("vettori")}`, value: "traccia_vettore", width: "50px" }, //TODO
        { text: "MTO", value: "traccia_mto", width: "50px" }, //TODO
        { text: `${this.tLabel("Stato")}`, value: "stato", width: "100px" },
        { text: `${this.tLabel("Num. Carri Arrivo")}`, value: "numCarriArrivo", width: "100px", sortable: false }, //TODO
        { text: `${this.tLabel("Num. Uti Arrivo")}`, value: "numCarichi", width: "100px", sortable: false },
        { text: `${this.tLabel("Num. Carri Partenza")}`, value: "numCarriPartenza", width: "100px", sortable: false }, //TODO
        { text: `${this.tLabel("Num. Uti Partenza")}`, value: "numScarichi", width: "100px", sortable: false },
      );
    }
  },
  methods: {
    getListaReportItems() {
      return [
        { title: "Modello 540", tipo: "540" },
        { title: "Modello 541", tipo: "541" },
        { title: "Lista Abbinamento", tipo: "Lista Abbinamento" },
      ];
    },
    disabledLavorazione(convoglio) {
      if (convoglio.stato === "INTERMINAL" || convoglio.stato === "INLAVORAZIONE" || convoglio.stato === "PRONTOUSCITA") {
        return false;
      }
      return true;
    },
    parseDate(date) {
      console.log("CALL parseDate", date);
      return new Date(date);
    },
    formatDate(date) {
      if (!date) return null;
      console.log("CALL formatDate", date);
      return date;
    },
    getVettoriViaggi(convoglio) {
      const vettori = [];

      if (convoglio.viaggioIn && convoglio.viaggioIn.traccia && convoglio.viaggioIn.traccia.vettore) {
        vettori.push(convoglio.viaggioIn.traccia.vettore);
      }
      if (convoglio.viaggioOut && convoglio.viaggioOut.traccia && convoglio.viaggioOut.traccia.vettore) {
        vettori.push(convoglio.viaggioOut.traccia.vettore);
      }
      return vettori;
    },
    getMTOViaggi(convoglio) {
      const mto = [];
      if (convoglio.viaggioIn && convoglio.viaggioIn.traccia && convoglio.viaggioIn.traccia.mto) {
        mto.push(convoglio.viaggioIn.traccia.mto);
      }
      if (convoglio.viaggioOut && convoglio.viaggioOut.traccia && convoglio.viaggioOut.traccia.mto) {
        mto.push(convoglio.viaggioOut.traccia.mto);
      }
      return mto;
    },
    initFilters(filters) {
      filters.escludiSenzaCarri = true;
    },
    modificaMostraVuoti(filter) {
      filter.escludiSenzaCarri = this.mostraVuoti ? null : true;
    },

    generaReport(tipo, viaggio) {
      switch (tipo) {
        case "540":
          this.stampaConvoglioArrivoPartenza("Arrivo", viaggio.id);
          break;
        case "541":
          this.stampaConvoglioArrivoPartenza("Partenza", viaggio.id);
          break;
        case "Lista Abbinamento":
          this.stampaListaTrenoAbbinamento(viaggio.id);
          break;
        default:
          break;
      }
    },
    async stampaConvoglioArrivoPartenza(tipo, convoglioId) {
      //modello arrivo - 540
      //modello partenza - 541
      try {
        const _callConfig = {
          responseType: "blob",
          headers: {
            "Content-Type": "application/octet-stream",
            Accept: "*/*",
            Pragma: "no-cache",
          },
        };
        var url = `${this.$apiConfiguration.BASE_PATH}gestioneConvogli/stampaModello` + tipo + "/" + convoglioId;

        const response = await this.$api.get(url, _callConfig);
        const fileURL = window.URL.createObjectURL(new Blob([response], {}));
        const fileLink = document.createElement("a");
        fileLink.setAttribute("href", fileURL);
        fileLink.setAttribute("download", "Modello" + tipo + ".xlsx");
        document.body.appendChild(fileLink);
        fileLink.click();
      } catch (e) {
        this.showError("Generazione Modello " + tipo + " non andata a buon fine");
        console.log(e);
      }
    },
    async stampaListaTrenoAbbinamento(convoglioId) {
      try {
        const _callConfig = {
          responseType: "blob",
          headers: {
            "Content-Type": "application/octet-stream",
            Accept: "*/*",
            Pragma: "no-cache",
          },
        };
        var url = `${this.$apiConfiguration.BASE_PATH}gestioneConvogli/stampaListaTrenoAbbinamento/` + convoglioId;
        const response = await this.$api.get(url, _callConfig);
        const fileURL = window.URL.createObjectURL(new Blob([response], {}));
        const fileLink = document.createElement("a");
        fileLink.setAttribute("href", fileURL);
        fileLink.setAttribute("download", "ListaTrenoAbbinamento.xlsx");
        document.body.appendChild(fileLink);
        fileLink.click();
      } catch (e) {
        this.showError("Generazione ListaTrenoAbbinamento non andata a buon fine");
        console.log(e);
      }
    },
  },
  computed: {
    csvData() {
      return this.items.map((item) => ({
        nome: item.nome,
        ETA: item.eta,
        "Viaggio In": item.viaggioIn ? item.viaggioIn.codice : "",
        Provenienza: item.viaggioIn && item.viaggioIn.nodoPartenza ? item.viaggioIn.nodoPartenza.codice : "",
        ETD: item.etd,
        "Viaggio Out": item.viaggioOut ? item.viaggioOut.codice : "",
        Destinazione: item.viaggioOut && item.viaggioOut.nodoArrivo ? item.viaggioOut.nodoArrivo.codice : "",
      }));
    },
  },
};
</script>

<style scoped>
.listViaggiDestOut {
  min-width: 200px;
}
</style>
